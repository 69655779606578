import React from 'react'
import { Link } from 'gatsby'
import './PostCard.css'
import Image from './../Image/Image'

const PostCard = ({
  image,
  title,
  excerpt,
  slug,
  czDate,
  categories = [],
  className = '',
  ...props
}) => (
  <Link to={slug} className={`PostCard ${className}`}>
    {image && (
      <div className="PostCard--Image relative">
        <Image background src={image.childImageSharp.fluid} alt={title} />
      </div>
    )}
    <div className="PostCard--Content">
      {title && image && <h3 className="PostCard--Title">{title}</h3>}
      {title && !image && <h3 className="PostCard--Title--Margin">{title}</h3>}
      <div className="PostCard--Category">
        {categories && categories.map(cat => cat.category).join(', ')}
      </div>
      {excerpt && <div className="PostCard--Excerpt">{excerpt}</div>}
      {
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '16px',
            color: '#707070'
          }}
        >
          {czDate && (
            <div>
              Publikováno:
              <div>{czDate}</div>
            </div>
          )}
          <div
            className="Button"
            style={{
              padding: '6px 12px',
              margin: '16px 0 16px auto',
              color: '#003A56',
              textTransform: 'initial'
            }}
          >
            Celý článek
          </div>
        </div>
      }
    </div>
  </Link>
)

export default PostCard
